<template>
  <b-container fluid>
    <h3 class="mb-5">
      <span v-if="isNewCommunication()" class="text-secondary">
        {{ $t("page.communication.createCommunication") }} -
      </span>
      <span v-else class="text-secondary">
        {{ $t("page.communication.updateCommunication") }} -
      </span>
      <span>{{ condominium.name }}</span>
    </h3>
    <v-stepper
      ref="stepper"
      :withDivider="false"
      :steps="steps"
      v-model="step"
      class="pb-4 mx-4"
    >
      <template slot="step-1">
        {{ $t("page.communication.stepOne.title") }}
      </template>
      <template slot="step-2">
        {{ $t("page.communication.stepTwo.title") }}
      </template>
      <template slot="step-3">
        {{ $t("page.communication.stepThree.title") }}
      </template>
    </v-stepper>

    <b-card :header-html="header">
      <template v-if="step === 1">
        <!-- Step 1 Content -->
        <step-two @isSelected="checkSelections"></step-two>
      </template>
      <template v-if="step === 2">
        <!-- Step 2 Content -->
        <step-three @formRready="checkForm"></step-three>
      </template>
      <template v-if="step === 3">
        <!-- Step 3 Content -->
        <step-four></step-four>
      </template>
      <b-row align-h="between">
        <b-col cols="6">
          <b-button
            class="text-white"
            v-if="step !== 1 && step !== 3"
            @click="previous"
          >
            {{ $t("button.previous") }}
          </b-button>
        </b-col>
        <b-col cols="6 text-right">
          <b-button
            v-if="step === 1"
            :disabled="!canNext"
            class="text-white"
            @click="next"
          >
            {{ $t("button.next") }}
          </b-button>
          <b-button
            v-else-if="step === 2"
            :disabled="!canSave"
            @click="createCommunication"
          >
            <div
              v-if="this.$router.history.current.name === 'NewCommunication'"
              class="text-white"
            >
              {{ $t("button.save") }}
            </div>
            <div v-else class="text-white">
              {{ $t("button.update") }}
            </div>
          </b-button>
          <b-button v-else>
            <div class="text-white" @click="updateLastStep">
              {{ $t("button.save") }}
            </div>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import StepTwo from "../components/Communication/CreationSteps/StepTwo";
import StepThree from "../components/Communication/CreationSteps/StepThree";
import StepFour from "../components/Communication/CreationSteps/StepFour";

import { VStepper } from "vue-stepper-component";

export default {
  components: {
    StepTwo,
    StepThree,
    StepFour,
    VStepper
  },
  data() {
    return {
      steps: 3,
      step: 1,
      canSave: false,
      canNext: false
    };
  },
  mounted() {},
  computed: {
    condominium() {
      return this.$store.getters["condominium/getCurrentCondominium"];
    },
    header() {
      var renderData = "";
      var icon = '<b-icon icon="file-earmark"></b-icon>';
      if (this.step === 1) {
        renderData =
          "<h5>" + this.$t("page.communication.stepOne.header") + "</h5>";
      } else if (this.step === 2) {
        renderData =
          "<h5>" + this.$t("page.communication.stepTwo.header") + "</h5>";
      } else {
        renderData =
          "<h5>" + this.$t("page.communication.stepThree.header") + "</h5>";
      }
      return icon + renderData;
    },
    settings() {
      return this.$store.getters["communication/getSettings"];
    },
    recipients() {
      return this.$store.getters["communication/getRecipients"];
    }
  },
  methods: {
    isNewCommunication() {
      if (this.$router.history.current.name === "NewCommunication") return true;
      return false;
    },
    getPayload() {
      var communicationForm = this.$store.getters[
        "communication/getCommunicationForm"
      ];
      var payload = {
        type: communicationForm.type,
        subject: communicationForm.subject,
        body: communicationForm.body
      };
      if (communicationForm.documents.length > 0 && this.isNewCommunication()) {
        payload["documents[]"] = communicationForm.documents;
      }
      if (this.recipients.length > 0 && this.isNewCommunication()) {
        for (let i = 0; i < this.recipients.length; i++) {
          payload[`recipients[${i}]`] = this.recipients[i];
        }
      }
      return payload;
    },
    async createCommunication() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        var call = "";
        var callData = {
          condominiumId: this.condominium._id,
          payload: this.getPayload()
        };
        if (this.isNewCommunication()) {
          call = "createCommunication";
        } else {
          call = "updateCommunication";
          callData.communicationId = this.$route.params.comId;
        }
        await this.$store
          .dispatch("communication/" + call, callData)
          .then(async () => {
            const removedRecipients = this.$store.getters[
              "communication/getEditRecipients"
            ].remove;
            if (removedRecipients.length > 0 && !this.isNewCommunication()) {
              for (let i = 0; i < removedRecipients.length; i++) {
                await this.$store.dispatch(
                  "communication/deleteCommunicationRecipient",
                  {
                    condominiumId: this.condominium._id,
                    communicationId: this.$route.params.comId,
                    recipientId: removedRecipients[i]
                  }
                );
              }
            }
          })
          .then(async () => {
            const addedRecipients = this.$store.getters[
              "communication/getEditRecipients"
            ].add;
            if (addedRecipients.length > 0 && !this.isNewCommunication()) {
              var payload = {
                recipients: []
              };
              for (let i = 0; i < addedRecipients.length; i++) {
                payload.recipients.push(addedRecipients[i]);
              }
              await this.$store.dispatch(
                "communication/addCommunicationRecipients",
                {
                  condominiumId: this.condominium._id,
                  communicationId: this.$route.params.comId,
                  payload: payload
                }
              );
            }
          })
          .then(async () => {
            const addedDocuments = this.$store.getters[
              "communication/getEditDocuments"
            ].add;
            if (addedDocuments.length > 0 && !this.isNewCommunication()) {
              await this.$store.dispatch("communication/addDocuments", {
                condominiumId: this.condominium._id,
                communicationId: this.$route.params.comId,
                payload: { "documents[]": addedDocuments }
              });
            }
          })
          .then(() => {
            const removedDocuments = this.$store.getters[
              "communication/getEditDocuments"
            ].remove;
            if (removedDocuments.length > 0 && !this.isNewCommunication()) {
              removedDocuments.forEach(documentid => {
                this.$store.dispatch("communication/deleteDocument", {
                  condominiumId: this.condominium._id,
                  communicationId: this.$route.params.comId,
                  documentId: documentid
                });
              });
            }
          });
      } catch (error) {
        console.log(error);
      } finally {
        await this.$store.dispatch("communication/retrieveCommunication", {
          condominiumId: this.condominium._id,
          communicationId: this.$store.getters["communication/getCommunication"]
            ._id
        });
        this.next();
        this.$store.commit("loader/RESET");
      }
    },
    async updateLastStep() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        var communication = this.$store.getters[
          "communication/getCommunication"
        ];
        var payload = {
          type: communication.type,
          subject: communication.subject,
          body: communication.body,
          readReceipt: this.settings.readReceipt,
          emailToAppUsers: this.settings.emailToAppUsers,
          paperToMailUsers: this.settings.paperToMailUsers
        };
        var callData = {
          condominiumId: this.condominium._id,
          communicationId: communication._id,
          payload: payload
        };
        await this.$store
          .dispatch("communication/updateCommunication", callData)
          .then(() => {
            const prevRouteName = this.$store.getters[
              "communication/getPrevRouteName"
            ];
            let routeParams = {};
            if (prevRouteName === "CondominiumManagement")
              routeParams = { condominiumId: this.condominium._id };
            this.$store.commit("communication/RESET");
            this.$router.replace({ name: prevRouteName, params: routeParams });
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    checkForm(check) {
      this.canSave = check;
    },
    checkSelections(check) {
      this.canNext = check;
    },
    next() {
      this.$refs.stepper.next();
    },
    previous() {
      this.$refs.stepper.previous();
    }
  }
};
</script>

<style lang="scss" scoped>
.v-stepper /deep/ .v-stepper-root /deep/ .v-step {
  pointer-events: none;
}
.v-stepper /deep/ {
  .v-stepper-root {
    justify-content: space-around;
  }
  .v-step {
    flex: unset;
    // /deep/ .label {
    //   display: unset;
    // }
    /deep/ .index {
      color: var(--primary);
      background-color: var(--gray-light) !important;
      border: unset;
      justify-items: center;
    }
    /deep/ .title {
      color: black;
      font-weight: bold;
    }
    .is-active {
      /deep/ .title {
        font-weight: 400;
      }
    }
  }
}
</style>
