<template>
  <div role="group">
    <label for="subject-text">{{ $t("subject") }}:</label>
    <b-form-input
      id="subject-text"
      v-model="subject"
      trim
      @update="checkingFields"
    ></b-form-input>
    <label for="body-text">{{ $t("description") }}:</label>
    <div class="editor-container">
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
        <div class="menubar">
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <b-icon icon="type-bold" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <b-icon icon="type-italic" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
          >
            <b-icon icon="type-strikethrough" />
          </button>
        </div>
      </editor-menu-bar>

      <editor-content class="editor__content" :editor="editor" />
    </div>

    <b-row class="pb-4">
      <b-col cols="4">
        <file-drop-uploader
          :fileTypes="allowedFileTypes"
          @addFiles="addFiles"
          :multiple="true"
        />
      </b-col>
      <b-col>
        <b-table small :items="items" :fields="fields">
          <template #head()="data">
            <div>
              {{ $t(data.label) }}
            </div>
          </template>
          <template #cell(remove)="data">
            <b-button
              variant="danger"
              size="sm"
              @click="removeFile(data.item.document, data.index)"
            >
              <span>{{ $t("button.delete") }} </span>
              <b-icon icon="backspace" />
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import { Bold, Italic, Strike } from "tiptap-extensions";
import FileDropUploader from "./../../../../../console-components/BaseFileDropUploader";

import { differenceWith, isEqual, find } from "lodash";

export default {
  components: {
    EditorMenuBar,
    EditorContent,
    FileDropUploader
  },
  data() {
    return {
      editor: null,
      fields: [
        { key: "document", label: "documents" },
        { key: "remove", label: "" }
      ],
      fileTypes: ["application/pdf"],
      docsSelected: [],
      add: [],
      remove: []
    };
  },
  computed: {
    communication() {
      return this.$store.getters["communication/getCommunication"];
    },
    items() {
      var data = [];
      var docOBJ = "";
      if (this.documents?.length > 0) {
        this.documents.forEach(document => {
          if (this.isNewCommunication()) {
            docOBJ = {
              document: document.name
            };
          } else {
            var docname = "";
            if (document.name) {
              docname = document.name;
            } else {
              docname = document;
            }
            docOBJ = {
              document: docname
            };
          }
          data.push(docOBJ);
        });
      }
      return data;
    },
    allowedFileTypes() {
      return this.fileTypes.join(",");
    },
    subject: {
      get() {
        return this.$store.getters["communication/getCommunicationForm"]
          .subject;
      },
      set(value) {
        this.$store.commit("communication/SET_FORM_SUBJECT", value);
      }
    },
    body: {
      get() {
        return this.$store.getters["communication/getCommunicationForm"].body;
      },
      set(value) {
        this.$store.commit("communication/SET_FORM_BODY", value);
      }
    },
    documents: {
      get() {
        return this.$store.getters["communication/getDocuments"];
      },
      set(value) {
        this.$store.commit("communication/SET_FORM_DOCUMENTS", value);
      }
    },
    documentsToAdd: {
      get() {
        return this.$store.getters["communication/getEditDocuments"].add;
      },
      set(value) {
        this.$store.commit("communication/SET_DOCUMENTS_TO_ADD", value);
      }
    },
    documentsToRemove: {
      get() {
        return this.$store.getters["communication/getEditDocuments"].remove;
      },
      set(value) {
        this.$store.commit("communication/SET_DOCUMENTS_TO_REMOVE", value);
      }
    }
  },
  mounted() {
    this.checkingFields();
    this.docsSelected = [...this.documents];
    this.editor = new Editor({
      extensions: [new Bold(), new Italic(), new Strike()],
      content: this.body,
      onUpdate: state => {
        this.body = state.getHTML();
        this.checkingFields();
      }
    });
    this.add = [...this.documentsToAdd];
    this.remove = [...this.documentsToRemove];
  },
  methods: {
    addFiles(files) {
      files.forEach(file => {
        this.docsSelected.push(file);
      });
      var data = differenceWith(this.docsSelected, this.documents, isEqual);
      data.forEach(item => {
        if (typeof item !== "string") this.add.push(item);
      });
      this.documentsToAdd = [...this.add];
      this.documents = [...this.docsSelected];
    },
    removeFile(file, index) {
      this.docsSelected.splice(index, 1);
      var data = differenceWith(this.documents, this.docsSelected, isEqual);
      data.forEach(item => {
        if (typeof item === "string") {
          var idToRemove = find(this.communication.documents, function(obj) {
            return obj.filename === item;
          });
          this.remove.push(idToRemove._id);
        } else {
          this.add = this.arrayRemove(this.add, item);
          this.documentsToAdd = [...this.add];
        }
      });
      this.documentsToRemove = [...this.remove];
      this.documents = [...this.docsSelected];
    },
    isNewCommunication() {
      if (this.$router.history.current.name === "NewCommunication") return true;
      return false;
    },
    arrayRemove(arr, value) {
      return arr.filter(function(ele) {
        return ele !== value;
      });
    },
    checkingFields() {
      if (this.body.length > 7 && this.subject.length > 0) {
        this.$emit("formRready", true);
      } else {
        this.$emit("formRready", false);
      }
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style lang="scss" scoped>
.editor-container {
  border: 1px solid #00000020;
  border-radius: 5px;
}
.editor__content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  /deep/ .ProseMirror {
    padding: 0px 10px;
    min-height: 10em;
    /deep/ p {
      margin: 0;
    }
  }
  /deep/ .ProseMirror-focused {
    outline: unset !important;
    padding: 0px 10px;
    // margin-right: 10px;
    border-radius: 0.25rem;
    color: #495057;
    background-color: #fff;
    border-color: #9be3e9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 197, 210, 0.25);
  }
}
.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
</style>
