<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row v-if="isNewCommunication()">
      <b-col lg="6">
        <search-input
          v-model="searchInput"
          label="search"
          :placeholder="$t('page.consumerUser.searchPlaceholder')"
          @search="search"
        ></search-input>
      </b-col>
      <b-col lg="6">
        <b-form-group :label="$t('role')" label-class="font-weight-bold">
          <b-form-checkbox-group v-model="filterRole" id="recipients-role">
            <b-form-checkbox
              v-for="(role, index) in consumerRoleOptions"
              :key="index"
              :value="role.value"
            >
              {{ $t(role.label) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col lg="12" class="text-right pb-3">
        <b-button class="mr-3 text-white" size="sm" @click="selectAllRows">
          {{ $t("button.selectAll") }}
        </b-button>
        <b-button class="text-white" size="sm" @click="clearSelected">
          {{ $t("button.clearAll") }}
        </b-button>
      </b-col>
    </b-row>

    <b-table
      ref="table"
      :fields="fields"
      :items="formatedUsers"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filterRole"
      :filter-included-fields="filterOn"
      :filter-function="customFilter"
      responsive="sm"
      select-mode="multi"
      selectable
      outlined
      hover
      show-empty
      small
      @row-selected="onRowSelected"
      @row-clicked="onRowClicked"
      @filtered="onFiltered"
    >
      <template #empty>
        <h6 class="text-center text-italic text-uppercase">
          {{ $t("page.condominium.emptyList") }}
        </h6>
      </template>
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
        </template>
      </template>
      <template #head()="data">
        <div
          :class="{ clickable: data.field.sort }"
          @click="data.field.sort && setSort(data.column)"
        >
          {{ $t(data.label) }}
          <svg
            v-if="data.column === sortBy"
            viewBox="0 0 16 16"
            class="sort-icon"
          >
            <path fill-rule="evenodd" :d="sortIcon"></path>
          </svg>
        </div>
      </template>
      <template #cell(fullName)="data">
        <b-avatar class="avatar mr-3" :src="data.item.photo" />
        {{ data.value }}
      </template>
      <template #cell(email)="data">
        {{ getMail(data.item) }}
      </template>
      <template #cell(telephone)="data">
        {{ data.value }}
      </template>
      <template #cell(status)="data">
        <b-badge
          class="text-white font-weight-normal"
          :variant="userStatusVariant[data.value]"
        >
          {{ $t(data.value) }}
        </b-badge>
      </template>
      <template #cell(role)="data">
        {{ $t(`userRoles.${data.value}`) }}
      </template>
    </b-table>
  </b-container>
</template>

<script>
import { userMixin } from "./../../../mixins";

import SearchInput from "./../../../../../console-components/BaseSearchInput";

export default {
  components: {
    SearchInput
  },
  mixins: [userMixin],
  data() {
    return {
      fields: [
        { key: "selected", label: "selected" },
        { key: "fullName", label: "nameAndSurname", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "telephone", label: "telephone", sort: false },
        { key: "status", label: "status", sort: false },
        { key: "role", label: "roleInCondominium", sort: true }
      ],
      consumerRoleOptions: [
        { label: "userRoles.doorman", value: "doorman" },
        { label: "userRoles.owner", value: "owner" },
        { label: "userRoles.tenant", value: "tenant" },
        { label: "userRoles.director", value: "director" }
      ],
      userStatusVariant: {
        active: "green",
        notverified: "cyan",
        invited: "yellow"
      },
      dataLoaded: false,
      totalRows: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: [],
      searchInput: "",
      filterOn: ["role"],
      filterRole: [],
      add: [],
      remove: []
    };
  },
  computed: {
    condominium() {
      return this.$store.getters["condominium/getCurrentCondominium"];
    },
    condominiumUsers() {
      return this.$store.getters["communication/getCondominiumUsers"];
    },
    formatedUsers() {
      if (this.dataLoaded && this.condominiumUsers.length > 0) {
        return this.condominiumUsers.map(item => {
          return {
            id: item._id,
            photo: this.getUserProfilePhoto(item.profile),
            fullName: this.getUserFullName(item.profile),
            email: item.email,
            telephone: this.getUserTelephone(item),
            role: this.getUserRole(item.condominiums),
            type: item.type,
            status: item.status
          };
        });
      } else return [];
    },
    sortIcon() {
      return this.sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    filterSearch: {
      get() {
        return this.$store.getters["communication/getFilterSearch"];
      },
      set(value) {
        this.$store.commit("communication/SET_FILTER_SEARCH", value);
      }
    },
    recipients: {
      get() {
        return this.$store.getters["communication/getRecipients"];
      },
      set(value) {
        var commitData = [];
        commitData = value.map(({ id }) => id);
        this.$store.commit("communication/SET_FORM_RECIPIENTS", commitData);
      }
    },
    selected: {
      get() {
        return this.$store.getters["communication/getEditRecipients"].selected;
      },
      set(value) {
        this.$store.commit("communication/SET_RECIPIENTS_SELECTION", value);
      }
    },
    recipientsToAdd: {
      get() {
        return this.$store.getters["communication/getEditRecipients"].add;
      },
      set(value) {
        this.$store.commit("communication/SET_RECIPIENTS_TO_ADD", value);
      }
    },
    recipientsToRemove: {
      get() {
        return this.$store.getters["communication/getEditRecipients"].remove;
      },
      set(value) {
        this.$store.commit("communication/SET_RECIPIENTS_TO_REMOVE", value);
      }
    }
  },
  created() {
    this.retrieveUsers();
  },
  mounted() {
    this.searchInput = this.filterSearch;
    this.totalRows = this.condominiumUsers.length;
    this.$store.commit("loader/SET_LOADER", {});
    if (!this.isNewCommunication()) {
      setTimeout(() => {
        this.initSelectRow(this.selected);
        this.$store.commit("loader/RESET");
      }, 800);
    } else {
      setTimeout(() => {
        this.initSelectRow(this.recipients);
        this.$store.commit("loader/RESET");
      }, 800);
    }
  },
  methods: {
    getUserProfilePhoto(profile) {
      return this.$_getUserProfilePhoto(profile);
    },
    getUserFullName(profile) {
      if (profile?.name && profile?.surname) {
        return `${profile.name} ${profile.surname}`;
      } else return "-";
    },
    getUserTelephone(user) {
      return (
        user?.identities?.find(item => {
          return item.type === "mobile" && item.status === "verified";
        })?.identityId || "-"
      );
    },
    getUserRole(condominiums) {
      return (
        condominiums.find(c => c.condominiumId === this.condominium._id)
          ?.role || "-"
      );
    },
    getMail(data) {
      if (data.type !== "guest") return data.email;
      return "-";
    },
    async retrieveUsers() {
      try {
        await this.$store.dispatch(
          "communication/retrieveCondominiumUsers",
          this.condominium._id
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.dataLoaded = true;
      }
    },
    setSort(sortBy) {
      if (this.sortBy !== sortBy) {
        this.sortBy = sortBy;
        this.sortDesc = false;
      } else {
        this.sortDesc = !this.sortDesc;
      }
    },
    search() {
      this.filterSearch = this.searchInput;
      this.retrieveUsers();
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    onRowSelected(items) {
      if (this.isNewCommunication()) {
        this.recipients = items;
        if (items.length > 0) this.$emit("isSelected", true);
        else this.$emit("isSelected", false);
      } else {
        var commitData = [];
        commitData = items.map(({ id }) => id);
        this.selected = commitData;
        if (this.selected.length > 0) this.$emit("isSelected", true);
        else this.$emit("isSelected", false);
      }
    },
    onRowClicked(item, index) {
      if (!this.isNewCommunication()) {
        if (!this.$refs.table.isRowSelected(index)) {
          if (!this.recipientExists(item.id)) {
            this.add.push(item.id);
            this.recipientsToAdd = this.add;
          } else {
            this.remove = this.arrayRemove(this.remove, item.id);
            this.recipientsToRemove = this.arrayRemove(this.remove, item.id);
          }
        } else {
          if (!this.recipientExists(item.id)) {
            this.add = this.arrayRemove(this.add, item.id);
            this.recipientsToAdd = this.arrayRemove(this.add, item.id);
          } else {
            this.remove.push(item.id);
            this.recipientsToRemove = this.remove;
          }
        }
      }
    },
    initSelectRow(recipients) {
      for (let j = 0; j < recipients.length; j++) {
        if (recipients[j]) {
          for (let i = 0; i < this.condominiumUsers.length; i++) {
            if (this.condominiumUsers[i]._id === recipients[j]) {
              this.$refs.table.selectRow(i);
            }
          }
        }
      }
    },
    selectAllRows() {
      this.$refs.table.selectAllRows();
    },
    clearSelected() {
      this.$refs.table.clearSelected();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    isNewCommunication() {
      if (this.$router.history.current.name === "NewCommunication") return true;
      return false;
    },
    recipientExists(id) {
      if (this.recipients.includes(id)) return true;
      return false;
    },
    arrayRemove(arr, value) {
      return arr.filter(function(ele) {
        return ele !== value;
      });
    },
    customFilter(data, filter) {
      return filter.includes(data.role);
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-icon {
  width: 20px;
}
.avatar {
  --size: 30px;
  width: var(--size);
  height: var(--size);
}
</style>
